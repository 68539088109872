<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:45:53
-->
<template>
  <div id="error">
    <div class="content">
      <p>出现错误请稍后再访问......</p>
      <van-button type="default" @click="reload">重新加载</van-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    reload() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
#error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 100%;
    text-align: center;
  }
}
</style>
